import React, { useEffect, useState } from "react";
import {
  DEFAULT_DATA_STATE,
  STUBBED_DATA_ACTIONS,
  PARA_CONFIG,
  NETWORK_BLOCK_REFRESH_TIME,
  MULTICALL_MARKET_IGNORE_LIST,
} from "./constants";
import { useData } from "./data-hooks";
import { useUserStore, UserStore } from "./user";
import { getMarketInfos, getRewardsStatus } from "../utils/contract-calls";
import { getAllTransactions } from "../apollo/client";
import { getDefaultProvider } from "../components/ConnectAccount/utils";
import { AppStatusStore } from "./app-status";
import { MARKET_LOAD_TYPE } from "../utils/constants";
import useIsTabActive from "./useIsTabActive";

export const DataContext = React.createContext({
  ...DEFAULT_DATA_STATE,
  actions: STUBBED_DATA_ACTIONS,
});

export const DataStore = {
  actionsSet: false,
  get: () => ({ ...DEFAULT_DATA_STATE }),
  actions: STUBBED_DATA_ACTIONS,
};

export const DataProvider = ({ loadType = MARKET_LOAD_TYPE.SIMPLIFIED, children }: any) => {
  const { account } = useUserStore();
  const configCashes = getCashesInfo();
  const isTabActive = useIsTabActive();
  const state = useData(configCashes);
  const {
    cashes,
    actions: { updateDataHeartbeat, updateTransactions },
  } = state;

  if (!DataStore.actionsSet) {
    DataStore.actions = state.actions;
    DataStore.actionsSet = true;
  }
  const readableState = { ...state };
  delete readableState.actions;
  DataStore.get = () => readableState;
  const networkId = Number(PARA_CONFIG.networkId);

  // WindowFocusHandler();
  useEffect(() => {
    let isMounted = true;
    // let intervalId = null;
    const getMarkets = async () => {
      const { account: userAccount, loginAccount } = UserStore.get();
      const { isRpcDown, isWalletRpc } = AppStatusStore.get();
      const { blocknumber: dblock, markets: dmarkets, ammExchanges: damm } = DataStore.get();
      const { actions: { setRewardsStatus, setIsRpcDown } } = AppStatusStore;
      const provider = isWalletRpc ? loginAccount?.library : getDefaultProvider() || loginAccount?.library;
      let infos = { markets: dmarkets, ammExchanges: damm, blocknumber: dblock };
      try {
        infos = await getMarketInfos(
          provider,
          dmarkets,
          damm,
          userAccount,
          MULTICALL_MARKET_IGNORE_LIST,
          loadType,
          dblock
        );

        if (isRpcDown) {
          setIsRpcDown(false);
        }

        const status = await getRewardsStatus(provider);
        setRewardsStatus(status);

        return infos;
      } catch (e) {
        console.error("Error getting market data", e);
      }
      return { markets: {}, ammExchanges: {}, blocknumber: null };
    };

    const fetchMarkets = () => {
      getMarkets().then(({ markets, ammExchanges, blocknumber }) => {
        if (isMounted && blocknumber && blocknumber > DataStore.get().blocknumber) {
          updateDataHeartbeat({ ammExchanges, cashes, markets }, blocknumber, null);
        }
      });
    }

    const fetchMarketData = async () => {
      try {
        const { account: userAccount, loginAccount } = UserStore.get();
        const { isRpcDown, isWalletRpc } = AppStatusStore.get();
        const { actions: { setRewardsStatus, setIsRpcDown } } = AppStatusStore;
        const provider = isWalletRpc ? loginAccount?.library : getDefaultProvider() || loginAccount?.library;
        console.log("getBlock", DataStore.get().blocknumber);
        const response = await fetch('https://beta-api.predictn.market/getMarket');
        // const response = await fetch('http://localhost:4000/getMarket');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const isMounted = true;
        const marketData = await response.json();
        // console.log('Market Data:', marketData);
        const blocknumber = marketData.data.blocknumber;
        let ammExchanges = marketData.data.ammExchanges;
        let markets = marketData.data.markets;
        // console.log("====", { ammExchanges, markets });
        if (isRpcDown) {
          setIsRpcDown(false);
        }
        const status = await getRewardsStatus(provider);
        setRewardsStatus(status);

        if (isMounted && blocknumber) {
          updateDataHeartbeat({ ammExchanges, cashes, markets }, blocknumber, null);
        }

      } catch (error) {
        console.error('Error fetching market data:', error);
      }
    };
    const fetchCustomMarketInfo = async () => {
      const response = await fetch(`https://fucate.climat.today/get-canned-markets`, {
        method: 'GET',
        headers: {
          'authToken': 'de4e6effabbe906f917814fab75b64a41f78a91f6419ff8ffde1ff7e71414c2c',
        }
      });
      const result = await response.json();
      if (result.length > 0) {
        let getCustomData = result.map((k) => ({
          marketInfo: k.marketInfo,
          id: k.id,
        }));
        localStorage.setItem("customInfo", JSON.stringify(getCustomData));
      }
    }
    // Initial fetch
    fetchCustomMarketInfo();
    fetchMarketData();
    const intervalId = setInterval(() => {
      if (localStorage.getItem('activeTab') == 'true') {
        fetchMarketData();
      }
    }, NETWORK_BLOCK_REFRESH_TIME[networkId]);
    return () => {
      isMounted = false;
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    let isMounted = true;
    const { actions: { setIsDegraded } } = AppStatusStore;
    const { isDegraded } = AppStatusStore.get();
    const fetchTransactions = () =>
      getAllTransactions(
        account?.toLowerCase(),
        (transactions) => isMounted && transactions && updateTransactions(transactions)
      )
        .then(() => {
          isDegraded && setIsDegraded(false)
        })
        .catch((e) => {
          !isDegraded && setIsDegraded(true);
        });

    fetchTransactions();
    const intervalId = setInterval(() => {
      fetchTransactions();
    }, NETWORK_BLOCK_REFRESH_TIME[networkId]);

    return () => {
      isMounted = false;
      clearInterval(intervalId);
    };
  }, [account]);

  return <DataContext.Provider value={state}>{children}</DataContext.Provider>;
};

export const useDataStore = () => React.useContext(DataContext);

const output = {
  DataProvider,
  useDataStore,
  DataStore,
};

// for now we jsut do this here...
const getCashesInfo = (): any[] => {
  const { marketFactories } = PARA_CONFIG;
  const { collateral: usdcCollateral } = marketFactories[0];
  // todo: need to grab all collaterals per market factory

  const cashes = [
    {
      name: "USDC",
      displayDecimals: 2,
      decimals: 6,
      address: usdcCollateral,
      shareToken: "",
      usdPrice: "1",
      asset: "",
    },
    {
      name: "ETH",
      displayDecimals: 4,
      decimals: 18,
      address: "0x980B62Da83eFf3D4576C647993b0c1D7faf17c73", // WETH address on Matic Mumbai
      shareToken: "",
      usdPrice: "2000",
      asset: "ETH",
    },
  ];

  return cashes;
};

export default output;
